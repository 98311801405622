<template>
  <div>
    <div class="relative report-vehicle-info">
      <section class="absolute top-0 right-0 report-category">
        {{ details.report_category }}
      </section>

      <section class="flex items-center">
        <img
          src="@/assets/img/scooter.png"
          class="object-cover w-20 h-24 rounded-full"
        />

        <div class="ml-4">
          <h6 class="font-extrabold text-14px text-muted">Vehicle QR</h6>
          <h2 class="font-extrabold text-19px text-oDark">
            <router-link
              v-if="details.vehicle_id"
              :to="{
                name: 'ViewVehicleProfile',
                params: { id: details.vehicle_id },
              }"
              target="_blank"
            >
              <div class="font-bold text-blue-500">
                #{{ details.vehicle_qr }}
              </div>
            </router-link>
            <div v-else>#{{ details.vehicle_qr }}</div>
          </h2>
        </div>
      </section>

      <section
        v-if="details.vehicle_parts && details.vehicle_parts.length > 0"
        class="flex flex-wrap items-center mt-10 space-x-2"
      >
        <div
          v-for="(part, partIndex) in details.vehicle_parts"
          :key="`part-${partIndex}`"
          class="tag"
        >
          {{ part }}
        </div>
      </section>

      <section class="mt-10 text-14px text-oCharcoal">
        <div class="font-extrabold">Description:</div>
        <div class="mt-1 font-medium word-wrap ">{{ details.description }}</div>
      </section>

      <section class="mt-10 font-extrabold text-14px text-oCharcoal">
        Report Photos:

        <div
          v-if="details.report_images.length > 0"
          class="grid grid-cols-1 mt-1 space-y-2 place-items-center lg:space-y-0 lg:space-x-2 lg:grid-cols-3"
        >
          <div
            v-for="(image, imageIndex) in getImages"
            :key="`report-image-${imageIndex}`"
            class="report-image"
          >
            <img
              :src="image.src"
              @click="image_viewing_index = imageIndex"
              class="object-cover w-full h-full cursor-pointer"
            />
          </div>
        </div>
        <div v-else class="mt-1 font-medium text-gray-400">
          No photos.
        </div>
      </section>
    </div>

    <CoolLightBox
      effect="fade"
      :items="getImages"
      :index="image_viewing_index"
      :useZoomBar="true"
      @close="image_viewing_index = null"
    />
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'VehicleInfoBlock',
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  components: {
    CoolLightBox,
    // DisplayAvatar: () => import("@/components/ui/DisplayAvatar")
  },
  data: () => ({
    image_viewing_index: null,
  }),
  computed: {
    getCategory() {
      if (!Array.isArray(this.details.report_category)) {
        return 'Unknown Type'
      }
      const first = this.details.report_category[0].toLowerCase()

      const formatted = first.replace(/_./g, (x) => {
        return ' '.concat(x.toUpperCase()[1])
      })
      const duplicate = formatted
      return duplicate
        .charAt(0)
        .toUpperCase()
        .concat(formatted.substring(1))
    },
    getImages() {
      if (this.details.report_images.length === 0) {
        return []
      }
      return this.details.report_images.map((item) => ({
        src: item.report_pic,
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
.report-vehicle-info {
  min-height: 576px;
  height: 100%;
  border-radius: 22px;
  border: solid 1px #e4e4e4;
  background-color: #fefdff;
  @apply px-8 py-5;

  & .text-muted {
    color: #a6a6ab;
  }

  & .tag {
    @apply flex items-center justify-center border border-gray-400 px-2 py-1 text-14px font-normal;
    border-radius: 8px;
    border: solid 1px #434343;
    background-color: #000000;
    text-align: center;
    color: #ffffff;
    width: 100px;
    height: 37px;
    margin: 3px 2px;
  }

  & .report-category {
    @apply flex items-center justify-center w-40 h-12 bg-red-200 text-oRed font-bold;
    border-top-right-radius: 22px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 4rem;
    border-bottom-right-radius: 0;
    min-width: 14rem;
    perspective: 100px;
  }

  & .report-image {
    @apply h-64 w-56 overflow-hidden transition duration-300 ease-in-out transform rounded-md shadow-md;

    &:hover {
      @apply scale-105;
    }

    @screen sm {
      @apply w-auto;
    }
  }
}

.word-wrap {
  word-wrap: break-word;
}
</style>
